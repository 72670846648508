.product-row {
  vertical-align: middle;
  font-size: 18px;
}

.product-row td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.product-row.selected,
.product-row.selected .dropdown {
  background-color: rgb(239, 239, 239);
}

.product-row .table-icons {
  text-align: center;
}

.product-row .table-icons i.onclick-enabled {
  cursor: pointer;
}

.product-row .table-icons .snelstart-icons {
  text-align: left;
}

.product-row i.false {
  color: red;
}

.product-row i.true {
  color: rgb(0, 179, 0);
}

.product-row p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.product-thumbnail {
  background-color: white;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19); */
}

.product-spinner {
  padding-top: 3.75rem;
}

.product-spinner span {
  position: relative;
  bottom: 0.4rem;
}

.actions-button {
  padding-left: 1rem;
}