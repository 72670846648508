.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

/* .ripple:active:after {
  transform: scale(0, 0);
  opacity: .2;
  transition: 0s;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background: radial-gradient(circle, #000 10%, transparent 10.01%) no-repeat 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
} */