.location-text {
  color: gray;
  font-size: 14px;
}

.temp-text {
  color: black;
  font-size: 40px;
  font-weight: bold;
}

.container {
  margin-top: 3rem;
}

.invalid-location-text {
  color: gray;
  font-size: 13px;
  font-style: italic;
}

.weather-info {
  display: flex;
  margin-top: 4.2rem;
}