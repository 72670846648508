.main-navbar {
  max-height: 1rem;
}

.main-body {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  margin-left: 2rem;
  min-height: 100vh
}

.main-content {
  flex-grow: 1;
}

.main-footer {
  border-top: 1px solid rgb(222, 222, 222);
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 4rem !important;
  margin-bottom: 4rem;
  padding-top: 1rem;
}

.main-footer > ul {
  list-style: none;
  display: inline-block;
  width: 50%;
}

.main-footer > ul.align-end {
  text-align: end;
  padding-right: 2rem;
}

.main-footer > ul.align-start {
  text-align: start;
}

.main-footer > ul.align-start > li {
  display: inline-block;
  padding-left: 2rem;
}

.main-footer > ul.align-end > li {
  display: inline-block;
  padding-left: 5rem;
}