.template-stage > .konvajs-content > canvas {
  border: 1px solid black !important;
  z-index: 100;
}

.template-stage {
  /* width: 60rem; */
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 2rem;
}

.code {
  display: none;
}