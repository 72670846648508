/* .rbc-row-content {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.rbc-row-content::-webkit-scrollbar {
  display: none;
} */

.rbc-time-content {
  overflow-y: hidden;
  align-items: stretch;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  top: 40%;
  bottom: 0;
  mix-blend-mode: difference;
}

.rbc-addons-dnd-resize-ns-icon {
  mix-blend-mode: difference;
}

.rbc-event {
  position: relative;
  overflow: visible;
}

.rbc-row-segment .rbc-event-content {
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  /* overflow: visible; */
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
  mix-blend-mode: difference;
  margin-top: 5px;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
  overflow: hidden;
}

.rbc-event-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.rbc-objects ul {
  list-style: none;
}

.rbc-button-link.rbc-show-more {
  position: absolute;
  top: -20px;
}

.rbc-item-status-icon svg {
  fill: #a0a0a0;
  color: #a0a0a0;
  mix-blend-mode: difference;
}

.rbc-item-status-icon svg path {
  fill: #a0a0a0;
  color: #a0a0a0;
  mix-blend-mode: difference;
}

.rbc-date-cell .rbc-button-link {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.rbc-calendar .rbc-time-view .rbc-time-header.rbc-overflowing {
  margin-right: unset !important;
}

.rbc-slot-selection {
  display: none;
}