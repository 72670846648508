img {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}
  
.form-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #212529;
}
  
.form-footer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6C757D;
    text-align: center;
}
  
.link-text {
    padding-bottom: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #4D7D6B;
}