.login-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  max-width: 60rem !important;
}

.form-check {
  margin-top: 0.438rem;
}

.btn-link {
  margin-bottom: 0.15rem;
}

.btn-forgot-password {
  box-shadow: none !important;
}

.btn-forgot-password:hover {
  color: #38bdfe !important;
}

.btn-show-hide:hover {
  color: black !important;
}