.cst-modal-body div img {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cst-modal-body div .col {
  padding-top: 1em;
  border-bottom: 2pt solid #CED4DA;
}

.cst-modal-body div .col-3 {
  border-bottom: 2pt solid #CED4DA;
}

.cst-modal-body .cst-modal-list {
  list-style-type: none;
  margin-right: 2em;
}

.cst-modal-body .cst-modal-list .cst-modal-list-item:last-child {
  padding-top: 0.5em;
}

.cst-modal-body .container{
  margin-top: 0px;
}

@media (max-width: 768px) {
  /* CUSTOM HEIGHTS */
  .h-md-100 { height: 100%!important;}
  /* .w-sm-15 { width: 15%!important; }
  .w-sm-20 { width: 20%!important; } */
}
@media (min-width: 768px) {
  /* CUSTOM HEIGHTS */
  .modal-content {
    height: auto !important;
    /* display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2); */
  }
}