.searchAndMultiFilter-search {
  width: 15rem !important;
}

.searchAndMultiFilter-search .search-input {
  border: 1px solid #CED4DA;
  border-radius: 100px 0px 0px 100px !important;
}

.searchAndMultiFilter-search .search-input-no-button {
  border: 1px solid #CED4DA;
  border-radius: 100px 100px 100px 100px !important;
}

.searchAndMultiFilter-search .search-button {
  margin-right: 0.5em;
  margin-bottom: 0.2em;
}

.searchAndMultiFilter-filter {
  margin-left: auto; 
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchAndMultiFilter-filter > .filter-label {
  min-width: 5rem;
  margin-right: 1rem;
}

.searchAndMultiFilter-filter > .basic-multi-select {
  width: 12.5rem;
}

.horizontal-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal-list li {
  display: inline-block;
  margin-top: 1em;
}

.search-multi-filter {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0;
}

.search-multi-filter > ul {
  list-style: none;
  padding-left: 0;
}

.search-multi-filter > ul > li {
  padding-bottom: 0.5rem;
}

.search-multi-filter > ul > li:last-child {
  padding-bottom: 0
}
