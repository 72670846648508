.product-modal p {
    font-size: 18px;
    text-align: left;
    padding-left: 0.6rem;
}

.product-modal svg {
    display: block
}

.ean-container {
    display: block;
}

.ean-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.category-container h5 {
    text-align: left;
    margin-left: 0.5rem;
    margin-bottom: 2rem;
}

.product-description {
    border: 1px solid rgb(58, 58, 58);
    border-radius: 10px;
    padding: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.modal-icons {
    display: inline;
}

.modal-icons i.false {
    color: red;
}

.modal-icons i.true {
    color: rgb(0, 179, 0);
}

.modal-copy-clipboard {
    background-color: #e0e0e0;
    margin-left: 0.5rem;
    transition: all 0.1s ease-in-out;
    border-radius: 0.5rem;
}

.modal-copy-clipboard:hover {
    transform: scale(1.1);
}

.narrowcasting-preview-parent {
    min-height: 40rem;
}

.narrowcasting-preview-parent > iframe {
    max-width: 100%;
}