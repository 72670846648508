.rack-row {
  vertical-align: middle;
  font-size: 18px;
}

.rack-row td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.rack-row p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.category-thumbnail {
  background-color: white;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19); */
}

.category-spinner {
  padding-top: 3.75rem;
}

.category-spinner span {
  position: relative;
  bottom: 0.4rem;
}