@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.toast {
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.transition-in {
  animation-name: fadeIn;
}

.transition-out {
  animation-name: fadeOut;
}

.subscription-expired-toast {
  width: 100% !important;
}

.subscription-expired-toast .toast-header {
  color: white;
  background-color: rgb(255, 0, 0);
}

.subscription-expired-toast .toast-body {
  background-color: rgb(255, 0, 0);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.subscription-expired-toast .toast-body .table {
  color: white;
}