.pagination-group {
  align-content: center;
}

.pagination-group button {
  font-size: .875rem;
  min-width: 2rem;
}

.pagination-group span {
  font-size: 0.875rem;
  min-width: 1rem;
  display: inline-flex;
  align-items: center;
}

.pagination-group {
  width: auto !important;
}

.pagination-filter {
  margin-left: auto; 
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination-filter > .filter-label {
  min-width: 5rem;
  margin-right: 1rem;
}

.pagination-filter > .basic-multi-select {
  width: 7rem;
}

.filter {
  max-width: 15rem !important;
}