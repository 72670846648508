.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline {
  box-sizing: border-box;
  font-size: 100%;
  background: #FFFFFF;
  /* border: 1px solid #E9ECEF; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-top-right-radius: 15px;
  /* border-radius: 15px; */
}

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.react-calendar-timeline .rct-item:hover {
  z-index: 88;
}

.rct-item {
  border-radius: 15px;
}

.rct-item:focus {
  background-color: #355f50 !important;
}

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
}

.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  border-left: 1px solid #bbb;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-color: rgb(78, 78, 78);
  z-index: 30;
}

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #bbb;
  box-sizing: border-box;
  z-index: 40;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: #F8F9FA;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: #F8F9FA;
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #bbb;
  cursor: default;
  font-size: 14px;
  background-color: #E9ECEF;
  border-left: 1px solid #bbb;
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  border-left: 1px solid #bbb;
  border-right: none;
}

.react-calendar-timeline .rct-header-root {
  border-bottom: none;
}

.react-calendar-timeline .rct-calendar-header {
  border: none;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background-color: #e1e1e1;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background-color: #ffffff;
}

.calendar-button-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

.calendar-button-container .calendar-button:first-child {
  border-top-left-radius: 15px;
  border-left: 0;
}

.calendar-button-container .calendar-button {
  color: rgb(255, 255, 255);
  background-color: #017ab5;
  margin-right: 0rem;
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid white;
}

.calendar-button-container .calendar-button.selected {
  background-color: #0197e2;
}

.calendar-button-container .calendar-button:last-child {
  border-top-right-radius: 15px;
}

.calendar-button-container .calendar-button:hover {
  background-color: #05acfe;
}