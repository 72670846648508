.sidebar {
  width: 3.688rem;
  transition: all .4s ease-in-out
}

.sidebar.open {
  width: 18.75rem;
  transition: all .4s ease-in-out
}

.sidebar-link {
  display: block;
  width: 3.75rem;
  position: relative;
  z-index: 2
}

.sidebar-link img {
  display: inline-block;
  vertical-align: middle;
  max-width: 50%
}

.sidebar-link h6 {
  white-space: nowrap;
  text-align: left;
  padding-left: 0.7rem;
  font-size: 18px;
  transform: translateY(0.4rem)
}

.sidebar-link p {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  box-sizing: border-box;
  transform: translateY(0.275rem)
}

.sidebar-link.show {
  opacity: 1;
  visibility: visible;
  transition: all .4s ease-in-out
}

.sidebar-link.hide {
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease-in-out
}

.sidebar-link.hide.accordion-item {
  bottom: 1.7rem;
  left: 1.5rem;
  width: 0.063rem;
  transition: all .4s ease-in-out
}

.sidebar-link.show.accordion-item {
  bottom: 1.7rem;
  left: 1.5rem;
  width: 18.75rem;
  transition: all .4s ease-in-out
}

.sidebar-item .accordion {
  position: inherit
}

.sidebar-item-text.show {
  opacity: 1;
  transition: all .4s ease-in-out
}

.sidebar-item-text.hide {
  opacity: 0;
  transition: all .4s ease-in-out;
  visibility: hidden
}

.sidebar-list {
  list-style-type: none;
  margin-top: 6.25rem;
  padding: 0;
  margin-left: 1.563rem
}

.sidebar-list-bottom {
  list-style-type: none;
  padding: 0;
  margin-left: 1.563rem;
  width: 1rem;
}

.sidebar-item {
  display: block;
  width: 4.688rem;
  margin-bottom: 0.688rem
}

.sidebar-item-accordion {
  display: block;
  width: 4.688rem;
  margin-bottom: 0.688rem;
}

.accordion-button {
  padding-right: 2.75rem !important;
  padding-left: 0 !important
}

.accordion-body ul {
  margin-top: 0.625rem;
  padding: 0;
  font-size: 16px;
}