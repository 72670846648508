.horizontal-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.horizontal-list li {
  display: inline-block;
  margin-top: 1em;
}

